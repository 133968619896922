import React, { useEffect, useState } from "react";

import Buttons from "../Buttons";

import "./whatPeep.css";

const WhatPeepsSay = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 460);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="container-fluid WhatPeepsSay">
      <div className="row py-5x">
        <div className="col-12 d-flex justify-content-center">
          <h2 className="mb-5">What People Say About Us</h2>
        </div>
      </div>
      <div
        className="row my-4x"
        style={{
          marginTop: isMobile ? "-22px" : "",
        }}
      >
        <div className="col-md-4 col-sm-6 my-5 min_he">
          <div className="what_peep_wrap-1">
            <div className="top-img">
              <img src="./our_agent-2.png" alt="" />
            </div>
            <div className="text-align-center mark2">
              <span className="">
                <b
                  style={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  &quot;
                </b>
                This company displays a high level of professionalism and ensure
                100% Customer satisfaction. At first, I was always skeptical
                about dealing with Real estate agents and Companies in Lagos.
                They allayed my fears and got me an Investment property of
                choice . I was impressed by how they handled the Transaction. I
                highly recommend their services. Keep it up!
                <b
                  style={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  &quot;
                </b>
              </span>
              <p className="my-1 fw-bold">Oyebade Bisola</p>
              <span className="">Realtor</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 my-5 min_he">
          <div className="what_peep_wrap-1">
            <div className="top-img">
              <img src="./our_agent-1.png" alt="" />
            </div>
            <div className="text-align-center mark2">
              <span className="">
                <b
                  style={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  &quot;
                </b>
                Wonderful estate firm. Their staff are very professional. When I
                moved my family to Lagos,they helped us settle down without any
                fuss. They are very reliable.{" "}
                <b
                  style={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  &quot;
                </b>
              </span>
              <p className="my-1 fw-bold">Chidi Onwueyi</p>
              <span className="">Realtor</span>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 my-5 min_he">
          <div className="what_peep_wrap-1">
            <div className="top-img">
              <img src="./reacltor3.jpg" alt="" />
            </div>
            <div className="text-align-center mark2">
              <span className="">
                <b
                  style={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  &quot;
                </b>
                Great company. One stop shop for all real estate investments
                <b
                  style={{
                    fontSize: "14px",
                    fontWeight: "bolder",
                  }}
                >
                  &quot;
                </b>
              </span>
              <p className="my-1 fw-bold">Chukky Ochonogor</p>
              <span className="">Realtor</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatPeepsSay;
